import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  cart: {},
  fetchOrders: false,
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setValue(state, action) {
      return Object.assign({}, state, action.payload)
    },
  },
})

export const { setValue } = cartSlice.actions
export default cartSlice.reducer
