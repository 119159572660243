import React from 'react'

// Dashboard
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

// Landing
const Landing = React.lazy(() => import('./views/pages/landing/Landing'))

// Checkout
const Checkout = React.lazy(() => import('./views/pages/checkout/Checkout'))

// Products request
const QuotationRequest = React.lazy(() => import('./views/pages/checkout/QuotationRequest'))

// Public product
const PublicProducts = React.lazy(() => import('./views/pages/landing/PublicProducts'))

// Public company
const PublicCompanies = React.lazy(() => import('./views/pages/landing/PublicCompanies'))

// My Products
const Products = React.lazy(() => import('./views/products/Products'))
const ProductForm = React.lazy(() => import('./views/products/Form'))

// My Stock
const Stock = React.lazy(() => import('./views/stock/Stock'))

// My quotation requests
const MyInquires = React.lazy(() => import('./views/inquires/Inquires'))
const NewMyInquire = React.lazy(() => import('./views/inquires/New'))
const MyInquireDetails = React.lazy(() => import('./views/inquires/Details'))
const EditMyInquire = React.lazy(() => import('./views/inquires/Edit'))
const CopyMyInquire = React.lazy(() => import('./views/inquires/Copy'))

// Buyer's quotations
const Quotations = React.lazy(() => import('./views/quotations/Quotations'))
const QuotationDetails = React.lazy(() => import('./views/quotations/Details'))
const SubmitProposal = React.lazy(() => import('./views/quotations/Submit'))

// Companies
const Companies = React.lazy(() => import('./views/companies/Companies'))

// Profile
const ProfileDetails = React.lazy(() => import('./views/profile/Details'))
const ProfileEdit = React.lazy(() => import('./views/profile/Edit'))

// My Organizations
const Organizations = React.lazy(() => import('./views/organizations/Organizations'))
const NewOrganization = React.lazy(() => import('./views/organizations/New'))
const EditOrganization = React.lazy(() => import('./views/organizations/Edit'))
const OrganizationDetails = React.lazy(() => import('./views/organizations/Details'))
const OrganizationSettings = React.lazy(() => import('./views/organizations/Settings'))

// Documents
const Documents = React.lazy(() => import('./views/documents/Documents'))
const NewDocument = React.lazy(() => import('./views/documents/New'))
const EditDocument = React.lazy(() => import('./views/documents/Edit'))
const CopyDocument = React.lazy(() => import('./views/documents/Copy'))
const DocumentDetails = React.lazy(() => import('./views/documents/Details'))
const NextPrepaymentInvoice = React.lazy(() => import('./views/documents/NextPrepaymentInvoice'))
const FinalInvoice = React.lazy(() => import('./views/documents/FinalInvoice'))

// Tasks
// const Tasks = React.lazy(() => import('./views/tasks/Tasks'))

const routes = [
  { path: '/', exact: true, name: 'Home', component: Landing },
  { path: '/checkout', exact: true, name: 'Checkout', component: Checkout },
  {
    path: '/quotationrequest',
    exact: true,
    name: 'Quotation Request',
    component: QuotationRequest,
  },
  { path: '/publicproducts/:id', exact: true, name: 'Public Products', component: PublicProducts },
  {
    path: '/publiccompanies/:id',
    exact: true,
    name: 'Public Companies',
    component: PublicCompanies,
  },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/profile', exact: true, name: 'Profile Details', component: ProfileDetails },
  { path: '/profile/edit', exact: true, name: 'Profile Edit', component: ProfileEdit },
  { path: '/products', exact: true, name: 'Products', component: Products },
  {
    path: '/products/new',
    exact: true,
    name: 'Create Product',
    component: ProductForm,
  },
  {
    path: '/products/:id',
    name: 'Product Details',
    component: ProductForm,
  },
  { path: '/products/:id/copy', name: 'Product Details', component: ProductForm },
  { path: '/products/:id/edit', name: 'Product Edit', component: ProductForm },
  { path: '/stock', exact: true, name: 'Stock', component: Stock },
  { path: '/inquires', exact: true, name: 'Quotation Requests', component: MyInquires },
  { path: '/inquires/new', exact: true, name: 'Create Inquire', component: NewMyInquire },
  { path: '/inquires/:id/copy', name: 'Quotation Request Copy', component: CopyMyInquire },
  { path: '/inquires/:id/edit', name: 'Quotation Request Edit', component: EditMyInquire },
  { path: '/inquires/:id', name: 'Inquire Details', component: MyInquireDetails },
  { path: '/tenders', exact: true, name: 'Tenders', component: Quotations },
  { path: '/tenders/:id', name: 'Tender Details', component: QuotationDetails },
  { path: '/tenders/:id/submit', name: 'Submit Proposal', component: SubmitProposal },
  { path: '/companies', exact: true, name: 'Companies', component: Companies },
  // { path: '/companies/:id', exact: true, name: 'Company details', component: Companies },
  { path: '/organizations', exact: true, name: 'Organizations', component: Organizations },
  {
    path: '/organizations/settings/:id',
    name: 'Organization Details',
    component: OrganizationSettings,
  },
  { path: '/organizations/:id', name: 'Organization Details', component: OrganizationDetails },
  {
    path: '/organizations/:id/edit',
    name: 'Organization Edit',
    component: EditOrganization,
  },
  {
    path: '/organizations/new',
    exact: true,
    name: 'Create/Join Organization',
    component: NewOrganization,
  },
  {
    path: '/documents',
    exact: true,
    name: 'Documents',
    component: Documents,
  },
  { path: '/documents/new', exact: true, name: 'Create Document', component: NewDocument },
  { path: '/documents/:id/copy', name: 'Document Copy', component: CopyDocument },
  { path: '/documents/:id/edit', name: 'Document Edit', component: EditDocument },
  { path: '/documents/:id', name: 'Document Details', component: DocumentDetails },
  {
    path: '/documents/:id/next',
    name: 'Next Prepayment Invoice',
    component: NextPrepaymentInvoice,
  },
  {
    path: '/documents/:id/final',
    name: 'Final Invoice',
    component: FinalInvoice,
  },
  // {
  //   path: '/tasks',
  //   exact: true,
  //   name: 'Tasks',
  //   component: Tasks,
  // },
]

export default routes
