import { createSlice } from '@reduxjs/toolkit'

// Define the type of an item in inquireitem_set
interface InquireItem {
  name?: string
  description?: string
  quantity?: number
  product?: string
  item_name_label?: string
  unit?: string
  [key: string]: any // Optional: Allows additional dynamic fields
}

const initialState = {
  id: '',
  name: '',
  description: '',
  status: 'DRAFT',
  proc_status: 'Draft',
  expire_at: '',
  budget: 0,
  currency: null,
  show_minimum_price: false,
  is_active: false,
  open: false,
  finished: false,
  local: false,
  delivery_time: null,
  delivery_term: '',
  payment_condition: '',
  inquireitem_set: [] as InquireItem[],
  participants: [],
  groups: [],
  inquiredocument_set: [],
  assigned_to: null,
  assigned_to_label: null,
  show_contact: false,
  errors: {},
}

const inquireSlice = createSlice({
  name: 'inquire',
  initialState,
  reducers: {
    setValue(state, action) {
      Object.keys(action.payload).forEach((key) => {
        if (key in state.errors) {
          delete state.errors[key as keyof typeof state.errors]
        }
      })
      Object.assign(state, action.payload)
    },
    reset(state, action) {
      return { ...initialState }
    },
    moveLotUp(state, action) {
      const index = action.payload
      if (index > 0) {
        // Swap lots directly using destructuring
        ;[state.inquireitem_set[index - 1], state.inquireitem_set[index]] = [
          state.inquireitem_set[index],
          state.inquireitem_set[index - 1],
        ]
      }
    },
    moveLotDown(state, action) {
      const index = action.payload
      if (index < state.inquireitem_set.length - 1) {
        // Swap lots directly using destructuring
        ;[state.inquireitem_set[index], state.inquireitem_set[index + 1]] = [
          state.inquireitem_set[index + 1],
          state.inquireitem_set[index],
        ]
      }
    },
    addLot(state) {
      state.inquireitem_set.push({})
    },
    removeLot(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      const newLots = lots.filter((_item: number, index: number) => index !== action.payload)
      state.inquireitem_set = newLots
    },
    updateLot(state, action) {
      // const { index, value } = action.payload
      // if (state.inquireitem_set[index]) {
      //   state.inquireitem_set[index] = { ...state.inquireitem_set[index], ...value }
      // }
      let lots = JSON.parse(JSON.stringify(state.inquireitem_set))
      lots[action.payload.index] = action.payload.value
      state.inquireitem_set = lots
    },
    addDocuments(state, action) {
      const files = action.payload
      let docs = JSON.parse(JSON.stringify(state.inquiredocument_set))
      for (let i = 0; i < files.length; i++) {
        docs.push({
          id: null,
          // TODO -> Set document path to file object
          document: files[i].name,
          name: files[i].name,
          // TODO -> Set url
          url: URL.createObjectURL(files[i]),
          size: files[i].size,
        })
      }
      // alert(JSON.stringify(docs))
      state.inquiredocument_set = docs
    },
    removeDocument(state, action) {
      let lots = JSON.parse(JSON.stringify(state.inquiredocument_set))
      const newLots = lots.filter((_item: number, index: number) => index !== action.payload)
      state.inquiredocument_set = newLots
    },
  },
})

export const {
  setValue,
  reset,
  moveLotUp,
  moveLotDown,
  addLot,
  removeLot,
  updateLot,
  addDocuments,
  removeDocument,
} = inquireSlice.actions
export default inquireSlice.reducer
