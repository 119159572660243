import './scss/style.scss'
import './Cookies.scss'
import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import routes from './routes'
import Cookies from './Cookies'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Landing = React.lazy(() => import('./views/pages/landing/Landing'))
const Checkout = React.lazy(() => import('./views/pages/checkout/Checkout'))
const PublicProducts = React.lazy(() => import('./views/pages/landing/PublicProducts'))
const PublicCompanies = React.lazy(() => import('./views/pages/landing/PublicCompanies'))
const QuotationRequest = React.lazy(() => import('./views/pages/checkout/QuotationRequest'))
const Login = React.lazy(() => import('./views/pages/Login'))
const Register = React.lazy(() => import('./views/pages/Register'))
const Registered = React.lazy(() => import('./views/pages/Registered'))
const ConfirmEmail = React.lazy(() => import('./views/pages/confirmEmail'))
const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Define a PrivateRoute component to protect routes that require authentication
const PrivateRoute = ({ children }) => {
  const authToken = localStorage.getItem('authToken')

  if (!authToken) {
    // return <Navigate to={{ pathname: '/login' }} replace />
    return <Navigate to="/login" replace />
  }

  return children
}

PrivateRoute.propTypes = {
  component: PropTypes.object,
  Navigate: PropTypes.object,
  location: PropTypes.any,
  children: PropTypes.any,
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      acceptedCookies: localStorage.getItem('acceptedCookies'),
      rejectedCookies: localStorage.getItem('rejectedCookies'),
    }
  }

  handleAccept = () => {
    localStorage.setItem('acceptedCookies', '1')
    this.setState({ acceptedCookies: '1' })
  }

  handleReject = () => {
    localStorage.setItem('rejectedCookies', '1')
    this.setState({ rejectedCookies: '1' })
  }
  render() {
    const { acceptedCookies, rejectedCookies } = this.state

    return (
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="spin-container">
              <Spin tip="Loading..." size="large">
                <div className="content-placeholder" />
              </Spin>
            </div>
          }
        >
          <div
            className={acceptedCookies === '1' || rejectedCookies === '1' ? null : 'cookie-main'}
          >
            <Routes>
              {/* Public routes */}
              <Route exact path="/" name="Home" element={<Landing />} />
              <Route exact path="/checkout" name="Checkout" element={<Checkout />} />
              <Route
                exact
                path="/quotationrequest"
                name="Buyer request"
                element={<QuotationRequest />}
              />
              <Route
                exact
                path="/publicproducts/:id"
                name="Public Product"
                element={<PublicProducts />}
              />
              <Route
                exact
                path="/publiccompanies/:id"
                name="Public Company"
                element={<PublicCompanies />}
              />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/signup" name="Register Page" element={<Register />} />
              <Route exact path="/registered" name="Register Page" element={<Registered />} />
              <Route
                exact
                path="/confirm-email/:token"
                name="Confirm Email"
                element={<ConfirmEmail />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Password"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/reset-password/:token"
                name="Reset Password"
                element={<ResetPassword />}
              />
              {/*
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    {/* Custom Suspense fallback with the spinner-grow for DefaultLayout */}
                    <Suspense
                      fallback={
                        <div className="spin-container">
                          <Spin tip="Loading..." size="large">
                            <div className="content-placeholder" />
                          </Spin>
                        </div>
                      }
                    >
                      <DefaultLayout />
                    </Suspense>
                  </PrivateRoute>
                }
              >
                {/* Nested private routes */}
                {routes.map(
                  (route, idx) =>
                    route.component && (
                      <Route key={idx} path={route.path} element={<route.component />} />
                    ),
                )}
              </Route>
            </Routes>
          </div>
          {acceptedCookies === '1' || rejectedCookies === '1' ? null : (
            <Cookies handleAccept={this.handleAccept} handleReject={this.handleReject} />
          )}
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App
