import { createSlice } from '@reduxjs/toolkit'
import { getSearchParams } from 'src/utils/params'

const initialState = {
  search: getSearchParams().get('search'),
  countries: getSearchParams().get('countries'),
  tariff: getSearchParams().get('tariff'),
  tariff_number: getSearchParams().get('tariff_number'),
  currency: getSearchParams().get('currency'),
  minprice: getSearchParams().get('minprice'),
  maxprice: getSearchParams().get('maxprice'),
  fetchData: false,
}

const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setValue(state, action) {
      return Object.assign({}, state, action.payload)
    },
  },
})

export const { setValue } = landingSlice.actions
export default landingSlice.reducer
